import styled from 'styled-components';
import { rgba } from 'polished';

export const Div = styled.div`
    padding: 15px;
    color: #ffffff;
    border-radius: 5px;
    border: 1px solid var(--lighter); 
    transition: all .5s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;


    &:hover {
        box-shadow: 0 5px 18px 0 ${rgba('#9bc8f280', 0.5)};
    }
`;

export const Image = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;

    span {
        left: 0;
        display: inline-block;
        border-radius: 9.5px;
        font-weight: 600;
        font-size: 11px;
        color: #ffffff;
        padding: 3px 10px;
        background: var(--danger);

        &:nth-of-type(1){
            top: 0;
        }

        &:nth-of-type(2){
            top: 23px;
        }
    }

`;

export const Name = styled.h3`
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: var(--dark);
    border-bottom: 1px solid var(--lighter);
    text-overflow: ellipsis;
    overflow: hidden; 
    padding: 1em 0;
    
    a {
        color: var(--dark);
        padding: 0;
        transition: all .5s ease;

        &:hover {
            color: var(--primary);
        }
    }
`;

export const Price = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        font-weight: 800;
        font-size: 18px;
        color: var(--primary);
        order: -3;

        small {
            display: block;
            font-size: 11px;
            color: var(--light);
        }

        a {
            color: var(--primary);
        }
    }

    > a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 31px;
        height: 31px;
        line-height: 26px;
        text-align: center;
        white-space: nowrap;
        padding: 0 10px;
        margin: 5px 0 0;
        background-color: var(--light);
        border-radius: 15.5px;
        transition: all .5s ease;
        overflow: hidden;
        transform: translateX(0);
        order: -1;

        small {
            color: #fff;
            font-size: 21px;
            transition: all .1s ease-out;
            opacity: 1;
        }

        b {
            color: white;
            display: none;
            font-size: 11px;
        }

        &:hover {
            width: 120px;
            background: var(--primary);

            small {
                opacity: 0;
                position: absolute;
            }

            b {
                display: inline-block;
            }

            & + div {
                opacity: 0;
                z-index: -1;
                display: none;
            }
        }
    }
`;

export const Brand = styled.div`
    display: block;
    width: 70px;
    height: 35px;
    position: relative;
    padding: 5px 0 0;
    opacity: 1;
    transition: all .5s ease-out;
    order: -2;
    z-index: 1;

    img {
        max-height: 100%;
        height: initial;
        width: initial;
        margin: auto;
        display: block;
    }
`;

export const ProductDescription = styled.div`
    color: #2b333d;
    font-size: 12px;
    line-height: 21px;
    text-align: left;
    font-weight: 400;
`;
