import { memo, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import min from 'lodash/min';
import pull from 'lodash/pull';
import { useI18n } from '@src/locales';
import { getProperty, getPropertyValueFromConfiguration, generateImageUrl, imageConfigurationKeys, stringifyPrice } from '@src/utils';
import * as Styles from './productblock.styled';

const Colors = dynamic(() => import('./Colors'), { ssr: true });

const shop = process.env.NEXT_PUBLIC_SHOP_NAME;

const ProductBlock = ({ product, catalog, model }) => {
    const initProduct = () => {
        let s;
        let j = 0;

        const selectedFilter = catalog.restFilter.properties['Item Color'];
        const order = getProperty(product[0], 'Item Color', true);

        if (selectedFilter) {
            uniqBy(product, `configuration_property.${order}.property_value_id`).forEach((p, i) => {
                if (selectedFilter.indexOf(getProperty(p, 'Item Color')?.property_value_id) !== -1) {
                    s = p;
                    j = i;
                }
            });
        }

        if (!s) {
            [s] = product;
        }

        return { s, j };
    };

    function getImageGadgets(config) {
        let img = '';

        // eslint-disable-next-line no-restricted-syntax
        for (const imageKey of imageConfigurationKeys) {
            if (config?.extra_info?.[imageKey]) {
                img = generateImageUrl({
                    name: imageKey,
                    url: config.extra_info[imageKey],
                    reseller: shop,
                }, imageKey === 'ImageMain' ? '500x500' : '');

                break;
            }
        }

        return img;
    }

    function getImageOffice(config) {
        return generateImageUrl({
            name: 'picture_standard_200_jpg',
            url: get(config.extra_info, 'picture_standard_200_jpg', config.extra_info.picture_200_jpg_0),
            reseller: shop,
        }, '200');
    }

    const [selected, setSelected] = useState(() => {
        const { s } = initProduct();
        return s;
    });
    const [index] = useState(() => {
        const { j } = initProduct();
        return j;
    });
    const [minPrice] = useState(() => {
        const pricesArray = product.map((p) => p.min_price);
        const pulledPricesArray = pull(pricesArray, 0);

        return min(pulledPricesArray);
    });
    const [image, setImage] = useState(() => {
        const getImage = {
            gadgets: getImageGadgets(selected),
            office: getImageOffice(selected),
        };

        return getImage[shop];
    });
    const { t } = useI18n();
    const { locale } = useRouter();

    function handleSelectTarget(config) {
        const getImage = {
            gadgets: getImageGadgets(config),
            office: getImageOffice(config),
        };

        setSelected((state) => ({
            ...state.selected,
            ...config,
        }));
        setImage(getImage[shop]);
    }

    function handleError() {
        setImage(null);
    }

    const link = `/${locale}/product/${model}_${selected?.id}`;

    const title = `${getPropertyValueFromConfiguration(selected, 'Model', 'name', locale)} ${getPropertyValueFromConfiguration(selected, 'Item Color', 'name', locale) || ''}`;

    return (
        <Styles.Div itemScope itemType="https://schema.org/Product">
            <Link href={link} passHref prefetch={false}>
                <Styles.Image>
                    {
                        image ? (
                            <Image
                                itemProp="image"
                                src={image}
                                layout="fill"
                                objectFit="contain"
                                alt={title}
                                title={title}
                                priority
                                onError={handleError}
                            />
                        ) : (
                            <Image
                                src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/notfound.jpg`}
                                width="200"
                                height="200"
                                layout="responsive"
                                alt={title}
                                title={title}
                            />
                        )
                    }

                    {selected?.promoPercentage ? <span>-{selected.promoPercentage}%</span> : ''}
                    {selected?.promoLastDate ? <span>Jusqu&lsquo;à {selected.promoLastDate}</span> : ''}
                </Styles.Image>
            </Link>

            {shop === 'gadgets' && <Colors configs={product} index={index} handleSelectTarget={handleSelectTarget} model={`${model}-${selected?.id}`} />}

            <Styles.Name>
                <Link href={link} passHref prefetch={false}>
                    <a itemProp="name" title={title}>{title}</a>
                </Link>
            </Styles.Name>

            <Styles.Price itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
                <div>
                    <small>{t('priceFrom')}</small>
                    <Link href={link} passHref prefetch={false}>
                        <a>
                            &euro;&nbsp;
                            {minPrice ? stringifyPrice(minPrice) : 0}
                            <meta itemProp="priceCurrency" content="Euro" />
                            <meta itemProp="lowPrice" content={minPrice ? stringifyPrice(minPrice) : 0} />
                        </a>
                    </Link>
                </div>
                <Link href={link} passHref prefetch={false}><a><small className="i-chevron-right" /><b>{t('seeProduct')}</b></a></Link>
                {shop === 'office' ? (
                    <Styles.Brand>
                        <Link href={link} passHref prefetch={false}>
                            <a>
                                <Image
                                    src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}${selected.extra_info.brand_icon_80_jpg}`}
                                    alt={selected.extra_info.Brand}
                                    layout="fill"
                                    objectFit="scale-down"
                                    quality={70}
                                />
                            </a>
                        </Link>
                    </Styles.Brand>
                ) : null}
            </Styles.Price>
        </Styles.Div>
    );
};

export default memo(ProductBlock);
